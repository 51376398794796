import React from 'react'
import { List, ListItem, ListItemText, ListItemIcon, ListItemButton
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { prettyDate, useApi } from '@clients/shared/src/utils';
import { Link, Button, Card, IconButton } from '../main';
import descriptions from '../messages/descriptions'


/** List of user's messages' settings. */
export default function MessagesSettings ({user, setUser}) {
  const { user_id, workplace_id, messages_settings = [] } = user;
  const api = useApi();
  const messageTypesSet =  
    [...(new Set(messages_settings.map(ms => ms.message_type)))];
  const onDelete = ms => 
    api.put('/messages-settings/' + ms.id, {...ms, deleted: true})
      .then(() => api.get('/users/' + user.id))
      .then(setUser);
  return(
    <Card
      alignItems='center'
      justifyContent='center'
      title='Réglages des messages périodiques'
      mt={2}
    >
      <List>
      {messageTypesSet.map( name =>
        <React.Fragment key={name}>
          <ListItem>
            <ListItemIcon><Send/></ListItemIcon>
            <ListItemText primary={descriptions[name]} />
          </ListItem>
          {messages_settings.filter(ms=> ms.message_type === name).map(ms=>
            <ListItem key={ms.id} dense
              secondaryAction={
                <IconButton type='delete' onClick={() => onDelete(ms)}/>}
            >
              <ListItemButton dense component={Link}
                to={'/messages-settings/' + ms.id}
              >
                <ListItemText inset>
                  <PrettySettings {...ms} />
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )}
        </React.Fragment>
      )}
        <ListItem alignItems='center'>
          <Button secondary fullWidth component={Link}
            disabled={user.deleted}
            to={{
              pathname:'/messages-settings/create',
              state:{recipient_id: user_id, place_id: workplace_id},
            }}
          >
            Ajouter un message
          </Button>
        </ListItem>
      </List>
    </Card>
  );
};


const PrettySettings = ({place, deleted, last_sent, message_type}) => {
  let text = (message_type === 'sent recap') ?  'de ' : 'pour ';
  text += place;
  text += last_sent ? ' (' + prettyDate(new Date(last_sent)) + ')' : '';
  return deleted ? <del>{text}</del> : <>{text}</>;
};
