import { useUserIs } from '../user/services';
import { stdDate } from '../utils/dates';


const menuLinks = [
  {label: 'Envois pleins', shortLabel: 'Envois', roles:['sender_full'],
    bacEmpty: false,
    bacClean: null,
    web: { uri: '/places' },
    mobile: { navigator: 'PlaceNavigator', screen: 'Places' }
  },
  {label: 'Envois vides', shortLabel: 'Envois', roles: ['sender_empty'],
    bacEmpty: true,
    bacClean: null,
    web: { uri: '/places' },
    mobile: { navigator: 'PlaceNavigator', screen: 'Places' }
  },
  {label: 'Réception pleins', shortLabel: 'Réception',
    roles: ['receiver_full'],
    bacEmpty: false,
    bacClean: null,
    web: { uri: '/returns' },
    mobile: { navigator: 'ReceptionNavigator', screen: 'Returns' },
    isReturn: true
  },
  {label: 'Réception vides', shortLabel: 'Réception',
    roles: ['receiver_empty'], 
    bacEmpty: true,
    bacClean: null,
    web: { uri: '/returns' },
    mobile: {navigator: 'ReceptionNavigator', screen: 'Returns'},
    isReturn: true
  },
  {label: 'Lavé', shortLabel: 'Lavé', roles: ['washer'], 
    bacEmpty: true,
    bacClean: true,
    web: { uri: '/returns' },
    mobile: {navigator: 'ReceptionNavigator', screen: 'Returns'},
    isReturn: true
  },
  {label: 'Réception sales', shortLabel: 'Réception',
    roles: ['receiver_dirty'], 
    bacEmpty: true,
    bacClean: false,
    web: { uri: '/returns' },
    mobile: {navigator: 'ReceptionNavigator', screen: 'Returns'},
    isReturn: true
  },
  {label: 'Historique', roles: ['user'],
    web: { uri: '/events/history', search: '?after=' + stdDate(-7) },
    mobile: { navigator: 'HistoryNavigator', screen: 'History' }
  },
  {label: 'Suivi livraisons', roles: ['courier_admin'],
    web: { uri: '/events/history', 
      search: '?after=' + stdDate(-7)+'&include=next_event&showDeliveries=true'}
  },
  {label: 'Gestion', roles: ['admin', 'leaser', 'logistics'],
    web: { uri: '/management' },
    mobile: { navigator: 'LeasingNavigator' }
  },
  {label: 'Synthèse', roles: ['admin'],
    web: { uri: '/reports/bacs' }
  },
  {label: 'Superadmin', roles: ['superadmin'],
    web: { uri: '/superadmin' }
  },
  {label: 'Aide',
    web: { uri: '/help' }
  },
  {label: 'Livraison', roles: ['courier'],
    mobile: { navigator: 'DeliveryNavigator', screen: 'Delivery' }
  }
];

const getLabel = (links, {label, shortLabel}) => {
  if (!shortLabel){
    return label;
  }
  const isShortLabelUnique =
    links.filter(link => link.shortLabel === shortLabel).length === 1;
  return isShortLabelUnique ? shortLabel : label;
}

export const useUserLinks = () => {
  const userIs = useUserIs();
  return menuLinks
    .filter(({roles}) => (!roles || roles.some(role => userIs(role))))
    .map((link, i, links) => ({
      ...link,
      label: getLabel(links, link)
    }))
}
