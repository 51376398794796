import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Checkbox, CheckboxWithLabel, TextField } from 'formik-mui';
import { Box, Typography, MenuItem, Stack, Avatar, FormGroup
  } from '@mui/material';
import { useApi, stdDate } from '@clients/shared/src/utils';
import { useCurrentUser } from '@clients/shared/src/user/services';
import { FormPage, SubmitButton, Card, Link } from '../main';
import PlaceAutocomplete from '../place/PlaceAutocomplete';


/** The form to create or edit a specific stock trigger. */
export default function StockTrigger({match, history}) {
  const api = useApi();
  const { workplace_id } = useCurrentUser();
  const [ trigger, setTrigger ] = useState({
    "id": match.params.stock_trigger_id,
    "deleted": false,
    "place_id": '',
    "lower_limit": '',
    "upper_limit": '',
    "model_id": '',
    "is_empty": '',
    "is_clean": '',
    "on_monday": false,
    "on_tuesday": false,
    "on_wednesday": false,
    "on_thursday": false,
    "on_friday": false,
    "on_saturday": false,
    "on_sunday": false,
    "time": 7,
    "do_email": true,
    "do_add_due": false,
    workplace_id
  });
  const [ models, setModels ] = useState([{id: '', name: 'Tous les modèles'}])
  const [ submitSucceeded, setSucceeded ] = useState(false);
  useEffect(() => {
    trigger.id && api.get()
      .then(convertToEmptyString)
      .then(setTrigger);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger.id]);
  useEffect(() => {
    api.get('/models').then(models => setModels(m => m.concat(models)))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const submitNew = values => api.post('/stock-triggers', convertToNull(values))
    .then(() => history.replace('/stock-triggers'))
  const submitUpdate = values => api.put(null, convertToNull(values))
    .then(convertToEmptyString)
    .then(setTrigger)
    .then(() => setSucceeded(true))
  const submit = trigger.id ? submitUpdate : submitNew;
  const getHistoryLink = values => `/events/history?after=${stdDate(-15)}`
    + `&workplace_id=${values.place_id}`
    + (values.model_id && `&model_id=${values.model_id}`)

  return (
    <FormPage
      title={`${trigger.id ? 'Modification' : 'Création'} d'une alerte`}
    >
      <Formik onSubmit={submit} initialValues={trigger} enableReinitialize>
        {({touched, errors, values}) => {
          return(
            <>
              <Card title={trigger.id ? 'Modification' : 'Nouvelle alerte'}>
                <Form>
                  <PlaceAutocomplete
                    label='Lieu concerné'
                    required
                    error={touched['place_id'] && !!errors['place_id']}
                    helperText={errors['place_id']}
                    sx={fieldsSpacing}
                  />
                  <Field
                    component={TextField}
                    name='lower_limit'
                    label='Stock min'
                    variant="standard"
                    type='number'
                    inputProps={{min: 0}}
                    fullWidth
                    sx={fieldsSpacing}
                  />
                  <Field
                    component={TextField}
                    name='upper_limit'
                    label='Stock max'
                    variant="standard"
                    type='number'
                    inputProps={{min: 0}}
                    fullWidth
                    sx={fieldsSpacing}
                  />
                  <Typography variant='body1'>
                    {renderLimitsExplanation(values)}
                  </Typography>
                  <Field 
                    component={TextField}
                    type='text'
                    name='model_id'
                    label='Modèle'
                    select
                    variant="standard"
                    fullWidth
                    sx={{mt: 1}}
                  >
                    {models.map(({id, name}) => 
                    <MenuItem key={id} value={id}>
                      <Typography variant='body1'>{name}</Typography>
                    </MenuItem>)}
                  </Field>
                  <Field 
                    component={TextField}
                    type='text'
                    name='is_empty'
                    label='Pleins ou vides'
                    select
                    variant="standard"
                    fullWidth
                    sx={fieldsSpacing}
                  >
                    {isEmptyOptions.map(({value, title}) => 
                    <MenuItem key={title} value={value}>
                      <Typography variant='body1'>{title}</Typography>
                    </MenuItem>)}
                  </Field>
                  <Field 
                    component={TextField}
                    type='text'
                    name='is_clean'
                    label='Propres ou sales'
                    select
                    variant="standard"
                    fullWidth
                    sx={fieldsSpacing}
                  >
                    {isCleanOptions.map(({value, title}) => 
                    <MenuItem key={title} value={value}>
                      <Typography variant='body1'>{title}</Typography>
                    </MenuItem>)}
                  </Field>
                  <DaysFields sx={{mt: 4}}/>
                  <Field 
                    component={TextField}
                    type='text'
                    name='time'
                    label='Heure'
                    select
                    variant="standard"
                    fullWidth
                    sx={fieldsSpacing}
                  >
                    {Array.from(Array(24).keys()).map(value => 
                    <MenuItem key={value} value={value}>
                      <Typography variant='body1'>{`${value} h`}</Typography>
                    </MenuItem>)}
                  </Field>
                  <FormGroup sx={fieldsSpacing}>
                    <Field
                      component={CheckboxWithLabel}
                      type='checkbox'
                      name='do_email'
                      Label={{label:'Envoyer un mail d’alerte'}}
                    />
                    <Field
                      component={CheckboxWithLabel}
                      type='checkbox'
                      name='do_add_due'
                      Label={{label: 'Créer un contrat'}}
                    />
                    <Field
                      component={CheckboxWithLabel}
                      type='checkbox'
                      name='deleted'
                      Label={{label: 'Effacé'}}
                    />
                  </FormGroup>
                  <SubmitButton {...{submitSucceeded}}/>
                </Form>
              </Card>
              {values.place_id && 
              <Link target="_blank" sx={{mt: 5}} to={getHistoryLink(values)}>
                Voir l’historique du lieu.
              </Link>}
            </>
          )
        }}
      </Formik>
    </FormPage>
  );
};

const isEmptyOptions = [
  {title: 'Non défini', value: ''},
  {title: 'Bacs pleins', value: false},
  {title: 'Bacs vides', value: true},
]

const isCleanOptions = [
  {title: 'Non défini', value: ''},
  {title: 'Bacs propres', value: true},
  {title: 'Bacs sales', value: false},
]

const days = [
  {name: 'on_monday', french: 'Lu'},
  {name: 'on_tuesday', french: 'Ma'},
  {name: 'on_wednesday', french: 'Me'},
  {name: 'on_thursday', french: 'Je'},
  {name: 'on_friday', french: 'Ve'},
  {name: 'on_saturday', french: 'Sa'},
  {name: 'on_sunday', french: 'Di'},
]

const DaysFields = ({sx}) =>
  <Box sx={sx}>
    <Typography variant='body1' color="text.secondary">Jours</Typography>
    <Stack direction="row" spacing={3} justifyContent="space-between">
      {days.map(({name, french}) => (
      <Field
        key={name}
        name={name}
        component={Checkbox}
        type="checkbox"
        icon={<Avatar sx={{bgcolor:'grey.light'}}>{french}</Avatar>}
        checkedIcon={<Avatar sx={{bgcolor: 'primary.main'}}>{french}</Avatar>}
        sx={{px: 0}}
      />
      ))}
    </Stack>
  </Box>

const renderLimitsExplanation = ({upper_limit, lower_limit}) => {
  if (upper_limit === '' && lower_limit === ''){
    return 'Aucun seuil défini.'
  }
  let explanation = "Se déclenche si le stock est ";
  if (lower_limit !== ''){
    explanation += `inférieur à ${lower_limit} bacs`;
  }
  if (upper_limit !== '' && lower_limit !== ''){
    explanation += " ou s'il est ";
  }
  if (upper_limit !== ''){
    explanation += `supérieur à ${upper_limit} bacs`;
  }
  return explanation + ".";
}

const convertToEmptyString = (obj) => 
  Object.keys(obj)
    .reduce(
      (acc, key) => ({ ...acc, [key]: obj[key] === null ? '' : obj[key] })
    , {})

  const convertToNull = (obj) => 
  Object.keys(obj).reduce(
    (acc, key) => ({ ...acc, [key]: (obj[key] === '' ) ? null : obj[key] })
  , {});

  const fieldsSpacing = { mt : 2.5 }
