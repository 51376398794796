import React from 'react';
import { styled } from '@mui/system';
import { Grid } from '@mui/material';
import { pluralize } from '@clients/shared/src/utils';
import { H6, Sub1, Frame } from '../main';


/** A summary of a group of events with one destination. */
export default function EventsGroupCard ({ isDelivery, group }) {
  const {is_clean, created, destination} = group[0];
  const withFollowups = group[0].hasOwnProperty('next_event');
  const title = isDelivery 
    ? destination 
    : is_clean ? 'LAVÉ' : `RECEPTION${(is_clean===false) ? ' SALE' : ''}`;
  const lastTime = new Date(created).toLocaleTimeString(
    'fr-FR', {hour: 'numeric', minute: 'numeric'});
  const delivereds = withFollowups && 
    group.filter(e => e.next_event?.is_passage).length
  const redirecteds = withFollowups && 
    group.filter(e => e.next_event?.destination).length
  const notReturned = !withFollowups && 
    group.filter(event => !event.closed).length
  const details = [
    (delivereds !== false) && pluralize('livré', delivereds),
    (redirecteds !== false) && pluralize('renvoyé', redirecteds),
    isDelivery && (notReturned!==false) && pluralize('$non rendu', notReturned),
    !isDelivery && (notReturned !== false) && (notReturned + ' en stock'),
  ].filter(d => d !== false).join(' - ');
  return (
    <StyledGrid container
      alignItems='center'
      justifyContent='space-between'
    >
      <Grid item container alignItems='center'>
        <Grid item container>
          <Frame small secondary={isDelivery}>
            <Sub1>{group.length}</Sub1>
          </Frame>
          <H6 color='textSecondary' className={classes.title}>{title}</H6>
        </Grid>
        <Sub1 className={classes.details}>
          <span>{details}</span>
        </Sub1>
      </Grid>
      <Sub1 sx={{ display: { xs: 'none', lg: 'block'} }} >
        dernier scan à <b>{lastTime}</b>
      </Sub1>
    </StyledGrid>
  );
}

const PREFIX = 'EventsGroupCard';

const classes = {
  title: `${PREFIX}-title`,
  details: `${PREFIX}-details`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(3, 6),
  '&:hover': {
    backgroundColor: theme.palette.grey.light,
  },
  [`& .${classes.title}`]: {
    fontWeight: 1000,
    color: theme.palette.text.secondary,
  },
  [`& .${classes.details}`]: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main
  }
}));
