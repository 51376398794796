import React, { useRef } from 'react';
import { Button as BaseMuiButton, Box, Popper } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

export default function Button({children, secondary, showSuccess=false, mt=4, 
  ...others}){
  const buttonRef = useRef();
  const variant = secondary ? 'outlined' : 'contained';
  return (
    <Box {...{mt}}>
      <BaseMuiButton
        variant={variant}
        ref={buttonRef}
        {...others}
      >
        {children}
      </BaseMuiButton>
      <Popper 
        open={showSuccess}
        anchorEl={buttonRef.current} 
        placement='right-end'
      >
        <CheckCircle color='primary'/>
      </Popper>
    </Box>);
};
