//Curent user actions
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const RESET_CURRENT_USER = "RESET_CURRENT_USER";

//Events actions
export const ADD_EVENT = "ADD_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const REPLACE_UNSYNC_EVENT = "REPLACE_UNSYNC_EVENT";
export const LOAD_EVENTS = "LOAD_EVENTS";
export const SET_EVENTS_SYNC_REQUIRED = "SET_EVENTS_SYNC_REQUIRED";
export const SET_EVENTS_IS_SYNCING = "SET_EVENTS_IS_SYNCING";

//Places actions
export const ADD_PLACE = "ADD_PLACE";
export const LOAD_PLACES = "LOAD_PLACES";
export const SET_PLACE = "SET_PLACE";
export const DELETE_PLACE = "DELETE_PLACE";
export const SET_PLACES_LOAD_REQUIRED = "SET_PLACES_LOAD_REQUIRED";
export const SET_PLACES_IS_LOADING = "SET_PLACES_IS_LOADING";
export const INITIALIZE_PLACES = "INITIALIZE_PLACES";

//ScanParams actions
export const REMOVE_DESTINATION = "REMOVE_DESTINATION";
export const SET_DESTINATION = "SET_DESTINATION";
export const SET_BAC_EMPTY = "SET_BAC_EMPTY";
export const SET_BAC_CLEAN = "SET_BAC_CLEAN";

//Tours actions
export const UPDATE_TOUR = "UPDATE_TOUR";
export const LOAD_TOURS = "LOAD_TOURS";
export const ADD_TOUR = "ADD_TOUR";
export const SET_TOURS_LOAD_REQUIRED = "SET_TOURS_LOAD_REQUIRED";
export const SET_TOURS_IS_LOADING = "SET_TOURS_IS_LOADING";
export const INITIALIZE_TOURS = "INITIALIZE_TOURS";

//Utils actions
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
