import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { colors, typography } from '@clients/shared/src/theme';

const theme = responsiveFontSizes(createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 600,
      lg: 960,
      xl: 1280
    },
  },
  palette: {
    primary: colors.primary,
    secondary: colors.secondary,
    text: colors.text,
    error: {
      main: '#e23d49',
    },
    defaultProps: {
      white: '#FFFFFF',
    },
    grey: {
      dark: '#0000008a',
      main: colors.grey,
      light: colors.lightGrey
    },
    blue: {
      main: colors.blue
    },
    red: {
      main: colors.red
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 
      `${typography.fontFamily.regular}, -apple-system, BlinkMacSystemFont`,
    fontSize: 16, // px
    htmlFontSize: 20, // px
    h1: {
      fontFamily: `${typography.fontFamily.bold}`,
      fontSize: '3.5rem'
    },
    h2: {
      fontFamily: `${typography.fontFamily.bold}`,
      fontSize: '1.4rem',
      color: colors.text.secondary,
    },
    h5: {
      fontFamily: `${typography.fontFamily.bold}`
    },
  },
  borders: {
    light: '1px solid rgba(0, 0, 0, 0.12)'
  },
  spacing: factor => 4 * factor // px
}));

const themeWithProps = {
  shadows: {
    defaultProps: {
      inner: 'inset 2px 0.5px 4px rgba(0, 0, 0, 0.2)',
      outer: '3px 1px 4px rgba(0, 0, 0, 0.2)',
      outerExtra: '0px 0.5px 10px rgba(0, 0, 0, 0.5)'
    },
  },
  header: {
    defaultProps: {
      height: {
        default: theme.spacing(15),
        xs: theme.spacing(12)
      },
      padding: {
        default: theme.spacing(4, 0),
        xs: theme.spacing(3, 0)
      }
    },
  },
  subheader: {
    defaultProps: {
      height: {
        default: theme.spacing(12),
        xs: theme.spacing(10)
      }
    },
  },
  bacCard: {
    defaultProps: {
      height: 92,
      maxWidth: 300,
    },
  }
}

const themeWithOverrides = {
  MuiDrawer: {
    styleOverrides: {
      paper: {
        borderRight: theme => theme.borders.light
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        paddingTop: 0,
        paddingBottom: 0,
        '&.Mui-selected': {
          backgroundColor: theme.palette.defaultProps.lightGrey,
          boxShadow: themeWithProps.shadows.defaultProps.inner
        },
        minHeight: 0,
        height: themeWithProps.subheader.defaultProps.height.default,
        [theme.breakpoints.between('xs', 'md')]: {
          height: themeWithProps.subheader.defaultProps.height.xs,
        }
      },
    }
  },
  MuiCssBaseline: {
    styleOverrides: {
      a: {
        textDecoration: 'none',
        backgroundColor: 'black'
      },
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(4),
        '&:last-child': {
          paddingBottom: theme.spacing(4)
        }
      }
    }
  },
  MuiGrid: {
    styleOverrides: {
      container: {
        width: 'auto'
      }
    }
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        }
      },
      outlined: {
        border: 'none',
        borderBottom: `1px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          border: 'none',
          borderBottom: `1px solid transparent`,
        }
      }
    },
  },
  label: {
    styleOverrides: {
      '& + .MuiInput-formControl': {
        marginTop: 0
      }
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginRight: 0
      }
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&.Mui-expanded': {
          margin: 0
        }
      }
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      content: {
        '&.Mui-expanded': {
          margin: 0
        },
      }
    }
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: 0
      }
    }
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.grey.dark,
      }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      edgeEnd: {
        marginRight: -8
      },
    }
  },
  MuiAvatar: {
    defaultProps: {
      variant: 'square'
    },
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.secondary,
        fontWeight: 400
      }
    }
  },
}

const themeComplete = {
  ...theme,
  components: {
    ...themeWithProps,
    ...themeWithOverrides
  },
}
export default themeComplete;
