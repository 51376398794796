export const colors = {
  primary: {
    main: '#b7faac',
    light: '#D0FDC9',
    dark: '#35A424',
  },
  secondary:{
    main: '#FDF55B',
    light: '#FFFF9C',
  },
  grey: '#EEF0EE',
  lightGrey: '#F4F3EF',
  blue: '#CCE9FF',
  red: '#FFB6C1',
  text: {
    primary: '#111111',
    secondary: '#424242',
    grey: '#808080',
    highlight: '#b7faac',
  },
}
