import React, { memo, useEffect } from 'react';
import { Grid, Box, useMediaQuery } from '@mui/material';
import { FixedSizeGrid, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTours } from '@clients/shared/src/tour/services';
import { useCurrentUser } from '@clients/shared/src/user/services';
import { useSetBacClean, useSetBacEmpty
  } from '@clients/shared/src/scan/services';
import { usePlaces, usePlacesIsLoading
  } from '@clients/shared/src/place/services';
import { Load } from '../_utils';
import { SearchBar, useSearch } from '../searchbar';
import { IconButton, Page, H5 } from '../main';
import PlaceCard from './PlaceCard';

/** The component used to display the list of all places */
export default function Places({location}) {
  const setBacEmpty = useSetBacEmpty();
  const setBacClean = useSetBacClean();
  useEffect(() => {
    setBacEmpty(location.state?.bacEmpty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.bacEmpty]);
  useEffect(() => {
    setBacClean(location.state?.bacClean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.bacClean]);
  const isMd = useMediaQuery(theme => theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const isXl = useMediaQuery(theme => theme.breakpoints.up('xl'));
  const columnCount = isXl ? 4 : (isLg ? 3 : (isMd ? 2 : 1));
  //TODO see how to manage printing
  const isLoading = usePlacesIsLoading();
  const allPlaces = usePlaces();
  const tours = useTours();
  const { user_id, workplace_id } = useCurrentUser();
  const initialTour = tours?.find(
    ({drivers}) => drivers.some(({id}) => id===user_id)
  )
  const filters = [
    {template: 'placesByText'},
    {template: 'tours', items: tours, initialValue: initialTour?.id},
  ];
  const [searchBarProps, places] = useSearch(filters, allPlaces);
  return (
    <Page title='Points de livraison' 
      options={ <IconButton type='settings' to={'/places/edit'}/> }
    >
      <SearchBar {...searchBarProps}/>
      <Grid item xs>
        <div style={{height:'100%'}}>
          <Load isLoading={isLoading}>
            {(places.length===0) &&
            <Box pt={3}><H5 align='center'>Aucun lieu trouvé</H5></Box>}
          </Load>
          <AutoSizer>
            {({ height, width }) => (
            <FixedSizeGrid
              height={height}
              width={width}
              columnCount={columnCount}
              rowCount={parseInt(places.length/columnCount + 1)}
              columnWidth={parseInt(width/columnCount)}
              rowHeight={120}
              itemData={{places, columnCount}}
              itemKey={(props) => {
                const place = getPlace(props);
                return place ? place.id : -props.columnIndex}}
              overscanRowCount={3}
            >
              {Cell}
            </FixedSizeGrid>
            )}
          </AutoSizer>
        </div>
      </Grid>
    </Page>
  );
};

const getPlace = ({columnIndex, rowIndex, data}) => {
  const {places, columnCount} = data
  const index = rowIndex * columnCount + columnIndex;
  return (index < places.length) ? places[index] : null
};

const Cell = memo(({style, ...others}) => {
  const place = getPlace(others);
  return <div style={style}>{place && <PlaceCard place={place}/>}</div>
}, areEqual)
