import React, { useEffect, useState } from 'react';
import { useIsLoggedIn } from '../user/services';
import { useEvents, useEventsSyncRequired, useSyncScans, useSetEventsIsSyncing, 
  useSetRequireEventsSync, useSyncUpdates} from '../event/services';
import { useIsOnline } from './useIsOnline';

/** Empty component used to sync events with the db when device is online. */
export function DataSync () {
  const [copyEventsToSync, setCopyEventsToSync] = useState([]);
  const [syncAttempt, setSyncAttempt] = useState(0);
  const [isIdle, setIsIdle] = useState(false);
  const isLoggedIn = useIsLoggedIn();
  const isOnline = useIsOnline();
  const syncScans = useSyncScans();
  const syncUpdates = useSyncUpdates();
  const eventsSyncRequired = useEventsSyncRequired()
  const setRequireEventsSync = useSetRequireEventsSync();
  const setEventsIsSyncing = useSetEventsIsSyncing()
  const unsyncedEvents = useEvents().filter(({unsync}) => unsync);
  const shouldSync = isLoggedIn && isOnline && !isIdle && 
    (copyEventsToSync.length === 0) && (unsyncedEvents.length !== 0)
  const unsyncedUpdates = unsyncedEvents.filter(({bac_updates}) => bac_updates);
  useEffect(() => {
    shouldSync && setCopyEventsToSync((
      (unsyncedUpdates.length !== 0) ? unsyncedUpdates : unsyncedEvents
    ).slice(0, 10));
  }, [shouldSync]);
  useEffect(() => {
    if (copyEventsToSync.length > 0){
      setEventsIsSyncing(true);
      (unsyncedUpdates.length !== 0 ? syncUpdates : syncScans)(copyEventsToSync)
        .then(keepIdling => setSyncAttempt(a => keepIdling ? (a + 1) : 0))
        .finally(() => {
          setCopyEventsToSync([]);
          setEventsIsSyncing(false);
        })
    }
  }, [copyEventsToSync])
  useEffect(() => {
    setIsIdle(syncAttempt !== 0)
    const idleTime = idleTimes[syncAttempt] ?? idleTimes[idleTimes.length - 1];
    const timeoutId = setTimeout(() => {
      setIsIdle(false)
    }, idleTime * 60 * 1000);
    return () => clearTimeout(timeoutId);
  }, [syncAttempt]);
  useEffect(() => { 
    if (isOnline || eventsSyncRequired) {
      setSyncAttempt(0);
      setRequireEventsSync(false)
      setIsIdle(false);
    }
  }, [isOnline, eventsSyncRequired]);

  return null;
};

const idleTimes = [0, 1, 2, 5, 10, 20, 60, 6*60, 12*60] // in minutes
