import { differenceInHours } from 'date-fns/esm';
import { addDays, addHours, addMinutes, isSameDay, isToday, differenceInDays, 
  isAfter } from 'date-fns';
import { search, NONE_FILTER } from '../utils';


/** Count current week events. */
const countWeekEvents = (events) => {
  const today = new Date();
  return events.filter(event =>
    differenceInDays(today, new Date(event.created)) < 7
  ).length;
};


/** Filter today events. */
const todayEvents = (events) => {
  return events
    .filter(event => isToday(new Date(event.created)))
    .sort((a, b) => new Date(a.created) - new Date(b.created));
};


/** Filter last 12h events. */
const last12hEvents = (events) => {
  const twelveHoursAgo = addHours(new Date(), -12);
  return events.filter(
    event => isAfter(new Date(event.created), twelveHoursAgo));
};


/** Sort last 12h events. */
const sorted12hEvents = (events) => 
  last12hEvents(events)
    .sort((a, b) => isAfter(new Date(a.created), new Date(b.created)) ? 1 : -1)


/** Group events by periods. */
const groupByPeriod = events => sort(events).reduce(
  (periods, event) => {
    event.date = new Date(event.created);
    if (periods.length === 0) {
      return [[event]];
    }
    const period = periods[periods.length - 1];
    const start = period[0].date;
    const end = period[period.length - 1].date;
    const hoursSinceFirstEvent = differenceInHours(start, event.date);
    const hoursSinceLastEvent = differenceInHours(end, event.date);
    if (hoursSinceLastEvent <= 3 && hoursSinceFirstEvent <= 24) {
      period.push(event);
    } else {
      periods.push([event]);
    }
    return periods;
  },
  []);


/** Group events by delivery days. */
const groupByDeliveryDays = events => 
  sort(events).reduce((periods, event) => {
    event.deliveryDate = addHours(new Date(event.created), 9);
    if (periods.length === 0) {
      return [[event]];
    }
    const period = periods[periods.length - 1];
    if (isSameDay(event.deliveryDate, period[0].deliveryDate)) {
      period.push(event);
    } else {
      periods.push([event]);
    }
    return periods;
  }, []);


/** Sort events by creation date in descending order. */
const sort = events => {
  // TODO: use Dates instead of strings to properly handle timezones
  return events.sort((a, b) => (
    (a.client_created || a.created) > (b.client_created || b.created) ? -1
    : (a.client_created || a.created) < (b.client_created || b.created) ? 1
    : 0
  ))
};


/** Sort a list of items by the proterty 'destination'. */
const sortAlpha = list => {
  return list.sort((a, b) => a?.destination?.
      localeCompare(b.destination, 'fr', {'sensitivity': 'base'})
  );
}


/** Filter events. */
const filter = (unfiltered_events, filters) => {
  const { 
    text,
    after, 
    before, 
    notReturned, 
    showDeliveries, 
    showReturns,
    bac_model,
    bac_model_id,
    workplaceId,
    placeId,
    caretaker,
    is_clean,
    is_empty,
    uniqueBacId,
    is_passage
  } = filters;
  let events = sort(unfiltered_events);
  if (after && before) {
    events = filterByDate(events, after, before);
  }
  if (text) {
    const textRegExp = search.createRegExp(text.toString());
    events = events.filter(({destination}) =>
      destination && destination.match(textRegExp));
  }
  if (notReturned) {
    events = events.filter(event => !event.closed) 
  }
  if (placeId) {
    events = events.filter(event => event.destination_id==placeId) 
  }
  if (workplaceId && showReturns && !showDeliveries) {
    events = events.filter(event => event.destination_id===workplaceId) 
  }
  if (workplaceId && !showReturns && showDeliveries) {
    events = events.filter(event => event.destination_id!==workplaceId) 
  }
  if (bac_model) {
    const targetValue = bac_model === 'sans modèle' ? null : bac_model;
    events = events.filter(event => event.bac_model === targetValue);
  }
  if (bac_model_id) {
    // bac_model_id is defined as NONE_FILTER when filtering by 'sans modèle'
    const targetValue = bac_model_id === NONE_FILTER ? null : bac_model_id;
    events = events.filter(event => event.bac_model_id === targetValue);
  }
  if (caretaker) {
    const targetValue = caretaker === 'sans locataire' ? null : caretaker;
    events = events.filter(event => event.caretaker === targetValue);
  }
  if (is_clean !== undefined && is_clean !== '' ) {
    events = events.filter(event => event.is_clean === is_clean)
  }
  if (is_empty !== undefined && is_empty !== '' ) {
    events = events.filter(event => event.is_empty === is_empty)
  }
  if (uniqueBacId) {
    events = sort(events).reduce((uniques, e) => {
      !uniques.some(({bac}) => bac === e.bac) && uniques.push(e);
      return uniques
    }, [])
  }
  if (is_passage !== undefined) {
    events = events.filter(event => event.is_passage === is_passage) 
  }
  return events;
};




/** Filter events created between two dates. */
const filterByDate = (events, after, before) => {
  const offset = new Date().getTimezoneOffset();
  let start = addMinutes(new Date(after), offset);
  let end = addMinutes(addDays(new Date(before), 1), offset);
  if (after && typeof after.getMonth === 'function') {
    start = after;
  }
  if (before && typeof before.getMonth === 'function') {
    end = before;
  }
  return events.filter(event => {
    const eventCreation = new Date(event.created);
    return eventCreation > start && eventCreation < end
  });
};


/** Group events by destination. */
// TODO: Remove this function. Use groupBy(events, ['destination_id']) instead.
const groupByDestination = (events, placesById={}) => {
  const groupedObj = events.reduce((groups, event) => {
    const { destination_id } = event;
    if (!destination_id) {
      return groups;
    }
    if (!(groups[destination_id])) {
      groups[destination_id] = [];
    }
    groups[destination_id].push(event);
    return groups;
  }, {})
  const grouped = Object.entries(groupedObj).map(
    ([destination_id, events]) => ({
      events,
      destination_id,
      destination: events[0].destination || placesById[destination_id]?.name
    })
  )
  return sortAlpha(grouped);
}

/** Group events by a serie of event properties. */
const groupBy = (events, properties) => {
  const groups = {};
  events.forEach(event => {
    const groupIdentifier = properties.map(p => event[p]).join(', ');
    groups[groupIdentifier] ??= [];
    groups[groupIdentifier].push(event);
  })
  return Object.values(groups);
}

/** Counts the amount of events between each date interval
 * @param events : The events on which the function will be applied
 * @param intervals : The intervals of dates for which events have to be counted
 */
const countByDateIntervals = (events, intervals) => {
  return intervals.map((interval, index) => 
    filterByDate(events, interval, intervals[index+1]).length
  );
}


/** Groups events that have the same destination.
 * @param events : The events on which the function will be applied
 * @param after : The minimum date of events that will be counted
 * @param before : The maximum date of events that will be counted
 *
 * @returns {Array[{ name, count }]} of events count for each clients who has
 * been delivered between after and before
  */
const countByDestination = (events, after, before) => {
  const filteredEvents = filterByDate(events, after, before);
  const groupedEvents = groupByDestination(filteredEvents);
  const groupsCounts = groupedEvents
    .map(({destination, events}) => ({
      destination, 
      count: events.length
    }));
  return groupsCounts.sort((a, b) => b.count - a.count);
}


export const eventServices = {
  countWeekEvents,
  todayEvents,
  last12hEvents,
  sorted12hEvents,
  filter,
  filterByDate,
  countByDateIntervals,
  countByDestination,
  groupBy,
  groupByDestination,
  groupByPeriod,
  groupByDeliveryDays,
  sort,
  sortAlpha,
};
