import {
  SET_CURRENT_USER, RESET_CURRENT_USER
} from "../actionTypes";

const emptyUser = {
  workplaces: [],
}

const initialState = {
  user:  {
    isLoading: true,
    ...emptyUser
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER: {
      const {user} = action.payload;
      return {
        ...state,
        user,
      };
    }
    case RESET_CURRENT_USER: {
      return {
        ...state,
        user: emptyUser,
      };
    }
    default:
      return state;
  }
}

