import React, { useState, useEffect } from 'react'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useApi } from '@clients/shared/src/utils';
import { useUpdateUser, useCurrentUser,
  } from '@clients/shared/src/user/services';
import { Card, Tooltip } from '../main';


/** The form to add or remove role to an user. */
export default function Roles ({user, setUser}) {
  const api = useApi();
  const [ roles, setRoles ] = useState([]);
  const updateUser = useUpdateUser();
  const currentUser = useCurrentUser();
  useEffect(() => {
    api.get('/roles').then(setRoles);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeRole = (name, isChecked) => (
    setUser(({roles, ...other}) => ({
      roles: (() => {
        if (isChecked && (roles.indexOf(name)<0)) {
          return [...roles, name]
        } else if (!isChecked) {
          return roles.filter(role => role!==name)
        } else {
          return roles
        }})(),
      ...other, 
    }))
  );

  const handleChange = (event) => {
    const { name, checked } = event.target;
    changeRole(name, checked);
    const url = `/users/${user.id}/roles`;
    (checked ? api.post(url,{name}) : api.delete(url+'/'+name))
      .then(() => {(currentUser.user_id===user.id) && updateUser();})
      .catch(error => changeRole(name, !checked))
  };

  return(
    <Card mt={2} title='Groupes'>
      <FormGroup
        row
        sx={{
          maxWidth: 400,
          justifyContent: 'center',
          margin: 'auto',
        }}
      >
        {user.roles && roles.map(({name, label, description}) =>
          <FormControlLabel
            key={name} 
            label={<Tooltip text={description} value={label || name}/>}
            sx={{margin: 0}}
            control={ 
              <Checkbox 
                checked={user.roles.includes(name)}
                name={name}
                onChange={handleChange}
                disabled={user.deleted}
              /> 
            }
          />
        )}
      </FormGroup>
    </Card>
  )
};
