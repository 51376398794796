import { useState } from "react";
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { filterPlaceByText } from "@clients/shared/src/place/utils";
import { useCurrentUser } from "@clients/shared/src/user/services";
import { useWorkplace } from "@clients/shared/src/place";
import { NONE_FILTER } from "@clients/shared/src/utils";


//** Hook to get SearchBar's props and filtered items */
export function useSearch(partialFilters, allItems) {
  const filters = useFilters(partialFilters)
  const [searchValues, setSearchValues] = useSearchValues(filters);
  const items = filters
    .reduce(
      (items, {name, filterFn}) => 
        filterFn
          ? items.filter((item, i) => filterFn(searchValues[name], item, i))
          : items,
      allItems
    );
  return [
    {searchValues, setSearchValues, filters},
    items
  ]
}

//** Hook to initialize search values according the filters defintion and url */
function useSearchValues(filters) {
  const loc = useLocation();
  const search = qs.parse(loc.search, {parseBooleans: true, parseNumbers: true})
  if (search.text !== undefined) {search.text = String(search.text)};
  const initialValues = filters.reduce(
    (values, {name, initialValue}) => (
      {...values, [name]: initialValue || ''}
    ), {});
  return useState({
    ...initialValues,
    ...search
  });
}

/** Hook to add undefined props to filters with default values */
const useFilters = (filters) => {
  const user = useCurrentUser();
  const workplace = useWorkplace();
  return filters.map(filter => {
    const defaults = {};
    switch (filter.template) {
      case 'workplace':
        defaults.name = 'workplace_id';
        defaults.type = 'select';
        defaults.label = 'Lieu de travail';
        defaults.initialValue = user.workplace_id;
        defaults.clear = user.workplace_id;
        const mainWorkplace = {id: user.workplace_id, name: user.workplace};
        defaults.items = [...user.workplaces, mainWorkplace];
        const i = defaults.items.findIndex(({id}) => id === workplace.id);
        (i === -1) && defaults.items.unshift(workplace);
        defaults.hide = defaults.items.length < 2;
        break;
      case 'models':
        defaults.name = 'model_id';
        defaults.type = 'select';
        defaults.label = 'Par modèle';
        defaults.filterFn = (modelId, item) => {
          const key = filter.key || 'model_id';
          return !modelId 
            || item[key] === (modelId==='is_none' ? null : modelId)
        }
        break;
      case 'caretaker': // used with "light_data" events
        defaults.name = 'caretaker';
        defaults.type = 'select';
        defaults.label = 'Locataire';
        defaults.filterFn = (caretaker, item) => {
          const targetValue = caretaker === NONE_FILTER ? null : caretaker;
          return !caretaker || item.caretaker === targetValue
        }
        break;
      case 'caretaker_id': // used with "full" events only
        defaults.name = 'caretaker_id';
        defaults.type = 'select';
        defaults.label = 'Locataire';
        defaults.filterFn = (caretaker_id, item) => {
          const targetValue = caretaker_id === NONE_FILTER ? null : caretaker_id
          return !caretaker_id || item['bac_caretaker_id'] === targetValue
        }
        break;
      case 'tours':
        defaults.name = 'tourId';
        defaults.type = 'select';
        defaults.label = 'Par tournée';
        defaults.filterFn = (tourId, item) => {
          const key = filter.key || 'id';
          const tour = filter.items?.find(t => t.id===tourId)
          return !tourId || tour?.places.some(p=> p.id===item[key])
        }
        defaults.hide = filter.items?.length === 0;
        break;
      case 'clean':
        defaults.name = 'is_clean';
        defaults.type = 'booleanSelect';
        defaults.trueName = 'Propres';
        defaults.falseName = 'Sales';
        defaults.filterFn = (isClean, item) => 
          isClean === '' || item.is_clean === isClean
        break;
      case 'notReturned':
        defaults.name = 'notReturned';
        defaults.label = 'Non rendus';
        defaults.type = 'switch';
        defaults.filterFn = (isOn, item) => !isOn || !item.closed   
        break;
        case 'after':
          defaults.name = 'after';
          defaults.type = filter.withTime ? 'dateTime' : 'date'; 
          defaults.label = 'Entre';
          break;
        case 'before':
          defaults.name = 'before';
          defaults.type = filter.withTime ? 'dateTime' : 'date';
          defaults.label = 'Et';
          break;
      case 'placesByText':
        defaults.type = 'text';
        defaults.name = 'text';
        defaults.filterFn = (text, item) => filterPlaceByText(item, text);
        break;
    }
    return {...defaults, ...filter};
  })
  .filter(({hide}) => !hide);
}
