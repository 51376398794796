import React, {useState, useEffect} from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import qs from 'query-string';
import { useApi, isEmail, makeUrl } from '@clients/shared/src/utils';
import { useWorkplace } from '@clients/shared/src/place';
import { usePlaceOrState } from '@clients/shared/src/place/services';
import { FormPage, SubmitButton, Card } from '../main';

/** The form to create or edit new user in an external workplace . */
export default function VisibleUser ({ location, history, match }) {
  const search = qs.parse(location.search);
  const { id: workplace_id } = useWorkplace();
  const {place_id, visible_id} = match.params;
  const [ visible, setVisible ] = useState({
    title: '', email: '', id: visible_id || '', ...search});
  const [ submitSucceeded, setSucceeded ] = useState(false);
  const [nextUrl, setNextUrl] = useState('');
  const { get, post, put } = useApi();
  const [place, ] = usePlaceOrState(place_id);
  useEffect(() => {visible_id && get().then(setVisible)}, [get, visible_id])
  useEffect(() => {
    nextUrl && history.push(nextUrl); // clean way to let asynchronous finish
  }, [nextUrl, history]);
  const submit = values =>(place_id 
    ? post(makeUrl(`/places/${place_id}/visible-users`, {workplace_id}), values) 
    : put(null, values)
  )
    .then(setVisible).then(() => setSucceeded(true))
    .then(() => place_id &&
      setNextUrl(makeUrl('/users', {place_id, workplace_id})))
    .catch(e => {setSucceeded(false); return {e}});
  const name = visible.title || visible.email;
  const place_name = place.name || visible.workplace || '';

  return (
    <FormPage title={name || `Créer un compte`} subtitle={place_name}>
      <Card title={name ? 'Modifier' : 'Créer'}>
        <Formik onSubmit={submit} initialValues={visible} enableReinitialize>
          <Form>
            {fields.map(({name, label, type, validate}) => (
            <Field
              component={TextField}
              key={name}
              name={name}
              label={label}
              margin='dense'
              variant="standard"
              fullWidth
              {...{type}}
              {...{validate}}
              required={name==='email'}
            />))}
            <SubmitButton {...{submitSucceeded}}/>
          </Form>
        </Formik>
      </Card>
    </FormPage>);
};

export const fields = [
  {name: 'email', label: 'Email', type: 'email', validate: isEmail},
  {name: 'title', label: 'Intitulé'},
];
