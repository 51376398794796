import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { Box, Paper, Table, TableBody, TableRow, TableHead, TableCell,
  IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { stringifyDate, makeUrl } from '@clients/shared/src/utils';
import { useApi } from '@clients/shared/src/utils';
import useToursState from '@clients/shared/src/tour/toursState';
import { Load } from '../_utils';
import { SearchBar, useSearch, useGetModels } from '../searchbar';
import { Page, Link, Body1, Tooltip } from '../main';

/** Page showing immobized bacs by place. */
export default function DestinationsAnalytics ({location}) {
  const api = useApi({disableConcurrentRequest: true});
  const todayApi = useApi({disableConcurrentRequest: true});
  const [atPlaces, setAtPlaces] = useState([]);
  const [atPlacesToday, setAtPlacesToday] = useState([]);
  const search = qs.parse(location.search, {parseNumbers:true});
  const models = useGetModels(search);
  const { tours } = useToursState(search.workplace_id);
  const filters = [
    {template: 'workplace', label: 'Locataire'},
    {template: 'after'},
    {template: 'before'},
    {template: 'tours', items: tours},
    {template: 'models', items: models, filterFn: null},
    {name: 'days30', type: 'switch',label: '30 jours'},
    {name: 'disable_limit', type: 'switch', label: 'Immo > 6 mois',
      filterFn: null
    },
  ];
  const addStock = place => (
    {...place, stock: atPlacesToday.find(p => p.id===place.id)?.bacs || 0}) 
  const [searchBarProps, places] = useSearch(filters, atPlaces.map(addStock));
  const {searchValues} = searchBarProps;
  const {workplace_id, after, before, bac_model_id, days30} = searchValues
  useEffect(() => {
    api.get(makeUrl('/reports/immobilized', searchValues))
      .then(atPlaces => atPlaces.sort((a,b) => b.bacs - a.bacs))
      .then(setAtPlaces)
      .catch(() => setAtPlaces([]));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValues]);
  useEffect(() => {
    todayApi.get(makeUrl('/reports/immobilized', 
        {...searchValues, after:new Date(), before:''}))
      .then(setAtPlacesToday)
      .catch(() => setAtPlacesToday([]));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplace_id, bac_model_id, days30]);
  const totalBacs = places.reduce((tot, place) => tot + place.bacs, 0);
  const totalDays = places.reduce((tot, place) => tot + place.days, 0);
  const totalStock = places.reduce((tot, place) => tot + place.stock, 0);
  const url = makeUrl('/events/history', search);

  return (
    <Page title='Synthèse immobilisations'> 
      <SearchBar {...searchBarProps}/>
      <Paper sx={{width:{sm:'90%'}, my:{sm:4}, mx:'auto', p:4}}>
        <Body1>
          Un objet suivi est compté immobilisé lorsqu'il est resté dans un lieu 
          plus de {days30 ? 30 : 15} jours.
          {after && ` Il a pu être envoyé vers ce lieu avant le 
            ${stringifyDate(new Date(after))}.`}
          {before && ` Il a pu être repris avant 
            ${stringifyDate(new Date(before))}.`}
        </Body1>
      </Paper>
      <Load isLoading={api.isLoading || todayApi.isLoading}>
        <Box component={Paper} width={{sm:'90%'}} my={{sm:4}} mx='auto'>
          {places.length ?
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Nom</strong></TableCell>
                  <TableCell align='right'>
                    <strong>Immobilisations sur période</strong>
                    <Info 
                      text = "Nombre d’objets suivis immobilisés sur la période"
                    />
                  </TableCell>
                  <TableCell align='right'>
                    <strong>Jours d'immobilisations sur période</strong>
                  </TableCell>
                  <TableCell align='right'>
                    <strong>Immobilisations en cours</strong>
                    <Info
                      text = "Nombre d'objets suivis immobilisés aujourd'hui"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {places.map(({id, name, bacs, days, stock}) => (
                <TableRow hover key={id}>
                  <TableCell>
                    <Link to={url + '&text=' + name}>
                      <strong>{name}</strong> 
                    </Link>
                  </TableCell>
                  <TableCell align='right'>{bacs}</TableCell>
                  <TableCell align='right'>{days} j</TableCell>
                  <TableCell align='right'>{stock}</TableCell>
                </TableRow>))}
                {places.length>1 && 
                <TableRow hover>
                  <TableCell><strong>Total</strong></TableCell>
                  <TableCell align='right'>
                    <strong>{totalBacs}</strong>
                  </TableCell>
                  <TableCell align='right'>
                    <strong>{totalDays} j</strong>
                  </TableCell>
                  <TableCell align='right'>
                    <strong>{totalStock}</strong>
                  </TableCell>
                </TableRow>}
              </TableBody>
            </Table> : 'Aucune immobilisation trouvée'}
        </Box> 
      </Load> 
    </Page>
  );
};

const Info = ({text}) => ( 
<Tooltip text={text} sx={{paddingRight: 0}}>
  <IconButton >
    <InfoOutlined />
  </IconButton>
</Tooltip>
)
