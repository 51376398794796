import React from 'react';
import { addHours } from 'date-fns';
import { Avatar } from '@mui/material';
import { Link } from '../main';


/** A button that shows events count and link to history. */
export default function EventsCounter ({ count }) {
  return (
    <Avatar 
      component={Link} 
      to={`/events/history?after=${addHours(new Date(), -12).toISOString()}`}
      sx={styles.counter}
    >
      {count}
    </Avatar>
  );
}


const styles = {
  counter: {
    width: 40,
    height: 40,
    fontSize: 20,
    backgroundColor: 'primary.main',
  },
}
