import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer, MenuList, MenuItem, Box } from '@mui/material';
import { useLoadUser} from '@clients/shared/src/user/services';
import { useLinks } from '@clients/shared/src/links';
import { useGetBacClean, useGetBacEmpty
  } from '@clients/shared/src/scan/services';
import logo from '../assets/logos/Pandobac_logo_gr.png';
import Link from './Link';
import { H5 } from './Typography';


/** Sidebar menu. */
export default function DrawerMenu ({isOpen, toggle}) {
  return <>
    <Drawer
      sx={{ display: { xs: 'none', lg: 'block' } }}
      variant="permanent"
    >
      <Menu/>
    </Drawer>
    <Drawer
      sx={{ display: { xs: 'block', lg: 'none' } }}
      variant="temporary"
      open={isOpen}
      onClose={toggle}
    >
      <Menu {...{toggle}}/>
    </Drawer>
  </>;
}

const Menu = ({toggle}) => {
  const [selectedIndex, setSelectedIndex] = useState();
  const { pathname } = useLocation();
  const bacEmpty = useGetBacEmpty();
  const bacClean = useGetBacClean();
  useLoadUser();
  const links = useLinks();
  useEffect(()=>{
    setSelectedIndex(links.findIndex(
      ({to}) => (pathname.match(new RegExp('^' + to.pathname, 'i'))
        && bacEmpty === to.state?.bacEmpty
        && bacClean === to.state?.bacClean
      )
    ));
  }, [pathname, bacEmpty, bacClean, links]);
  return (
    <Box width={200} className='noPrint'>
      <MenuList style={{padding: 0}}>
        <Link onClick={toggle} to="/">
          <Box my={{xs:2.5, sm:4}} mx={5}>
            <img style={{maxWidth: '100%'}} src={logo} alt="Logo Pandobac"/>
          </Box>
        </Link>
        {links.map(({label, to}, index)=>
        <MenuItem
          component={Link}
          key={index}
          to={to}
          onClick={toggle}
          selected={selectedIndex === index}
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              height: 'auto',
            }
          })}
        >
          <Box py={3} pl={1}>
            <H5 sx={{fontSize: {xs:'1.5rem', md:'1.35rem'}}}>
              {label}
            </H5>
          </Box>
        </MenuItem>)}
      </MenuList>
    </Box>
  );
};
