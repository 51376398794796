import { useEffect, useState } from "react";
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { useCurrentUser } from "../user/services";
import { useApi } from "../utils";


/** Return the place from the url else the current user's workplace. */
export default function useWorkplace() {
  const api = useApi();
  const user = useCurrentUser();
  const { search } = useLocation();
  const { workplace_id } = qs.parse(search, {parseNumbers: true});
  const mainWorkplace = {id: user.workplace_id, name: user.workplace};
  const [ place, setPlace ] = 
    useState(workplace_id ? {id: workplace_id} : mainWorkplace);
  const workplaces = [...(user.workplaces || []), mainWorkplace];
  const i = workplaces.findIndex(({id}) => id === workplace_id);
  useEffect(() => {
    if (workplace_id) {
      (i === -1)
        ? api.get('/places/' + workplace_id).then(setPlace, ()=>{})
        : setPlace(workplaces[i]);
    } else {
      setPlace(mainWorkplace);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplace_id, i]);
  return place;
}
