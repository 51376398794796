import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/system';
import { Box, Grid, Tooltip, IconButton, InputAdornment } from '@mui/material';
import { Search, FilterList, Clear } from '@mui/icons-material';
import { endOfDay, parseISO } from 'date-fns';
import qs from 'query-string';
import { useFormikContext, Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { cleanObject } from '@clients/shared/src/utils';
import { Button, H5, Sub2,  } from '../main';
import { BooleanSelectField, SelectField, SwitchField, DateField, DateTimeField
} from './Fields';
import { BooleanSelectChip, GenericChip, SelectChip, DateChip } from './Chips';



/** The component used to display a search bar with filters. */
export default function SearchBar (props) {
  const { searchValues, setSearchValues, filters=[] } = props;
  const hasTextBar = filters.some(({type}) => type==='text');
  const [showFilter, setShowFilter] = useState(false);
  const history = useHistory();
  const { search: oldSearch, state } = history.location;
  const toggleFilter = () => setShowFilter(!showFilter);
  const search = qs.stringify(cleanObject({...searchValues}))
  useEffect(() => {
    if (oldSearch.slice(1)!==search) {
      history.replace({search}, state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  useEffect(() => {
    const endOfBefore = searchValues.before
      && filters.some(({type, name}) => type==='date' && name==='before')
      && endOfDay(parseISO(searchValues.before)).toISOString();
    if (endOfBefore && searchValues.before !== endOfBefore) {
      setSearchValues(v => ({...v,  before: endOfBefore}))
    }
  }, [searchValues.before]);
  const updateSearchOnBlur = () => {
    // TODO: supprimer cette function en changeant la logique des composants 
    // qui render inutilement après l'update de la query de l'url
    history.replace({search: qs.stringify(cleanObject(searchValues))}, state);
  }
  const [fields, chips] = filters
    .filter(({type}) => type!=='text')
    .reduce(
      ([fields, chips], {type, ...otherProps}) => {
        const [Field, Chip] = fieldAndChipComponents[type];
        return [
          [...fields, 
            props => <Field {...{...otherProps, ...props, searchValues}} />],
          [...chips, 
            props => <Chip {...{...otherProps, ...props, searchValues}} />]
        ]
      },
      [[], []]
    );

  return (filters.length===0 
    ? null
    : <StyledBox className='noPrint'>
      <Formik 
        initialValues={searchValues} 
        onSubmit={()=>null} 
        enableReinitialize
      >
        <Form>
          <FormValuesSaver {...{setSearchValues}} />
          <Grid container 
            className={classes.form}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs container>
              <Grid container spacing={1}>
                {chips.map((ChipComponent, index) => 
                <Grid item key={index}>
                  <ChipComponent {...{searchValues, setSearchValues}}/>
                </Grid>)}
              </Grid>
            </Grid>
            <Grid item container
              wrap='nowrap'
              alignItems='center'
              justifyContent='flex-end'
            >
              {fields && 
              <Tooltip title={<Sub2>Filtrer</Sub2>}>
                <IconButton onClick={toggleFilter} size="large">
                  <FilterList />
                </IconButton>
              </Tooltip>}
            </Grid>
            {showFilter && 
            <Box p={5} position='absolute' right={10} zIndex={1} 
              minWidth='200px' boxShadow={3} bgcolor='white' borderRadius="4px"
              className={classes.filterBox} 
            >
              <Box mb={2}>
                <H5>Filtrer</H5>
              </Box>
              <Grid container direction='column' rowSpacing={2}>
                {fields.map((Filter, index) => <Filter key={index}/>)}
              </Grid>
              <Button onClick={toggleFilter}>
                Fermer
              </Button>
            </Box>}
          </Grid>
          {hasTextBar && 
          <Box mt={1} px={4} py={2} className={classes.textContainer}>
            <Grid item xs container spacing={4}>
              <Grid item>
                <Box component={Search} height='100%' />
              </Grid>
              <Grid item xs>
                <Field
                  component={TextField}
                  name='text'
                  placeholder='Rechercher'
                  fullWidth
                  autoComplete='off'
                  variant="standard"
                  onBlur={updateSearchOnBlur}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      searchValues.text && <InputAdornment position="end">
                        <IconButton
                          onClick={() => 
                            setSearchValues(v => ({...v, text: ''}))}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>}
        </Form>
      </Formik>
    </StyledBox>
  );
}


const PREFIX = 'SearchBar';

const classes = {
  form: `${PREFIX}-form`,
  filterBox: `${PREFIX}-filterBox`,
  textContainer: `${PREFIX}-textContainer`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.form}`]: {
    height: theme.components.subheader.defaultProps.height.default,
    padding: theme.spacing(0, 5),
    backgroundColor: theme.palette.grey.light,
    boxShadow: theme.components.shadows.defaultProps.inner,
    [theme.breakpoints.between('xs', 'md')]: {
      height: theme.components.subheader.defaultProps.height.xs
    }
  },

  [`& .${classes.filterBox}`]: {
    top: 
      2 * Number(
        theme.components.subheader.defaultProps.height.default.slice(0, -2)
      ),
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      top:
        2 * Number(
          theme.components.subheader.defaultProps.height.xs.slice(0, -2)
        ) + 5,
    },
  },

  [`& .${classes.textContainer}`]: {
    boxShadow: theme.components.shadows.defaultProps.inner,
    backgroundColor: theme.palette.defaultProps.lightGrey,
  }
}));


const FormValuesSaver = ({ setSearchValues }) => {
  const { values, dirty } = useFormikContext();
  const timeoutId = useRef(null);
  if (dirty) {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => setSearchValues(values), 0);
  }
  return null;
}

const fieldAndChipComponents = {
  switch: [SwitchField, GenericChip],
  select: [SelectField, SelectChip],
  booleanSelect: [BooleanSelectField, BooleanSelectChip],
  date: [DateField, DateChip],
  dateTime: [DateTimeField, DateChip],
}
