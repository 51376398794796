import React from 'react';
import { Box } from '@mui/material';
import { CallMade, Email } from '@mui/icons-material';
import Page from './Page';
import ExternalLink from './ExternalLink';
import { H5 } from './Typography';


/** The help-page. */
export default function Help () {
  const helpUrl = 'https://www.pandobac.com/suivi-mode-demploi/' 
  const helpEmail = 'contact@pandobac.com' 
  return (
    <Page title='Aide'>
      <Box m={[5, 8]} >
        <H5>
          Lien pour accéder au support d'aide :
          <br/>
          <ExternalLink color='text.grey' href={helpUrl}>
            <CallMade fontSize='small' sx={{mb: -1, mr: 1}}/>
            Page d'aide au scan
          </ExternalLink>.
        </H5>
        <H5 mt={6}>
          Si la page d'aide n'est pas suffisante, 
          nous sommes disponibles par téléphone ou par mail :
          <br/>
          <ExternalLink color='text.grey' href={'mailto:' + helpEmail}>
            <Email fontSize='small' sx={{mb: -1, mr: 1}}/>
            contact@pandobac.com
          </ExternalLink>
          .
        </H5>
      </Box>
    </Page>
  );
};
