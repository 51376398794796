import React from 'react';
import {Line} from 'react-chartjs-2';
import { Card, CardContent } from '@mui/material';
import {eventServices} from '@clients/shared/src/event/event-services';
import {
  differenceInDays,
  differenceInMonths,
  startOfWeek,
  startOfMonth,
  addWeeks,
  addMonths,
  addDays,
  startOfDay,
} from 'date-fns/esm';

const dayIntervalsData = {
  get: 'getDate',
  set: 'setDate',
  differenceBetweenDates: differenceInDays,
  maxIntervalsNumber: 31,
  timeUnitStackedBy: 1,
  stringOptions: {
    month: 'short',
    day: 'numeric',
    weekday: 'short'
  },
  startOf: startOfDay,
  add: addDays
}

const weekIntervalsData = {
  ...dayIntervalsData,
  maxIntervalsNumber: 13,
  timeUnitStackedBy: 7,
  stringOptions: {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  },
  startOf: startOfWeek,
  add: addWeeks
}

const monthIntervalsData = {
  ...weekIntervalsData,
  timeUnitStackedBy: 1,
  get: 'getMonth',
  set: 'setMonth',
  differenceBetweenDates: differenceInMonths,
  startOf: startOfMonth,
  add: addMonths
}

const intervalsData = {
  days: dayIntervalsData,
  weeks: weekIntervalsData,
  months: monthIntervalsData
}

const getTimeIntervals = (start = new Date(), interval = 'days') => {
  const { get, set, differenceBetweenDates, maxIntervalsNumber,
    timeUnitStackedBy, startOf, add } = intervalsData[interval];

  const daysCnt = differenceBetweenDates(new Date(), start) + 1;
  const relativeDaysCnt = Math.ceil( daysCnt / timeUnitStackedBy);
  const intervalsCnt = Math.max(relativeDaysCnt, 1);

  const intervalsNumber = Math.min(intervalsCnt, maxIntervalsNumber);
  const emptyTimeIntervals = Array.from(Array(intervalsNumber), () => 0)

  const timeIntervals = emptyTimeIntervals.map((element, index) => {
    const interval = new Date(start.getTime());
    interval[set](start[get]() + index * timeUnitStackedBy);
    return startOf(interval);
  });

  // Add one day, week or month to take in account data 
  // between the begining of the day, week, or month and today 
  return timeIntervals.concat(
    add(timeIntervals[timeIntervals.length - 1], 1)
  );
}

const timeIntervalsToStrings = (timeIntervals, interval = 'days') => {
  return timeIntervals.map(
    timeInterval => timeInterval.toLocaleDateString(
      'fr-FR', intervalsData[interval].stringOptions
      )
    );
}

/** The chart to display user activity (deliveries and returns) */
export const ActivityLineChart = (props) => {
  const { interval, start, returns, deliveries } = props;
  const timeIntervals =  getTimeIntervals(new Date(start), interval);
  const data = {
    labels: timeIntervalsToStrings(timeIntervals, interval)
      .slice(0, timeIntervals.length-1),
    datasets: [
      {
        label: 'Bacs livrés',
        data: eventServices.countByDateIntervals(deliveries, timeIntervals),
        borderColor: 'rgba(253, 245, 91, 0.59)',
        lineTension: 0
      }, {
        label: 'Bacs réceptionnés',
        data: eventServices.countByDateIntervals(returns, timeIntervals),
        borderColor: 'rgba(183, 250, 172, 0.59)',
        lineTension: 0  
      }
    ]
  };
  const options = {
    title: {
      display: false
    },
    tooltips: {
      mode: 'index',
      intersect: true
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true
        }
      ],
      yAxes: [
        {
          ticks: {
            min: 0
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  };
  return (
    <Card className={props.className}>
      <CardContent>
        <Line 
          data={data} 
          options={options} 
          height={window.innerWidth < 600 ? 300 : 150}
        />
      </CardContent>
    </Card>
  );
}
