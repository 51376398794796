import React from 'react';
import { Grid, Typography, } from '@mui/material';
import BacCard from './BacCard';
import { stringifyDateTime } from '@clients/shared/src/utils';
import { Button, Sub2 } from '../main';


/** Display an event with all details. */
export default function Event ({event, isDelivery, actions, setBacAsLost}) {
  const { bac, bac_model, created, author, closed } = event;
  return (
    <Grid container sx={styles.grid}>
      <BacCard bacId={bac} closed={closed}/>
      <Grid container sx={styles.details}>
        <Typography>
          {stringifyDateTime(new Date(created))}
        </Typography>
        <Sub2 sx={{color: 'text.grey'}}>
          Scanné par {author}<br/>
          {bac_model || "Sans modèle"}
        </Sub2>
        {!closed &&
        <Sub2 color='error' className="noPrint">
          {isDelivery ? 'Non rendu' : 'En stock'}
        </Sub2>}
      </Grid>
        {actions && <Button secondary
          children='Bac perdu'
          disabled={closed}
          onClick={setBacAsLost}
          sx={{margin: 'auto 0'}}
        />}
    </Grid>
  );
}

const styles = {
  grid: {
    width: '100%', 
    px: 1,
    py: 4,
    justifyContent: 'space-evenly',
    '@media print': {
      py: 0
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media print': {
      flexDirection: 'row',
      gap: 3,
      alignItems: 'center'
    }
  },
};
