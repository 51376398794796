import React from 'react';
import { Typography } from '@mui/material';
import { colors } from '@clients/shared/src/theme';

const makeVariant = variant => 
  React.forwardRef((props, ref) => 
    <Typography variant={variant} {...props} ref={ref} />);
export const H1 = makeVariant('h1');
export const H2 = makeVariant('h2');
export const H3 = makeVariant('h3');
export const H4 = makeVariant('h4');
export const H5 = makeVariant('h5');
export const H6 = makeVariant('h6');
export const Body1 = makeVariant('body1');
export const Sub1 = makeVariant('subtitle1');
export const Sub2 = makeVariant('subtitle2');


export const Highlight = React.forwardRef((
  {children, backgroundColor, variant, ...props }, ref
) => 
  <Typography
    component="span"
    variant="inherit"
    {...props}
    ref={ref}
    sx={{
      backgroundColor: backgroundColor || colors.text.highlight,
      padding: '0 4px',
    }}
  >
    {children}
  </Typography>
);
