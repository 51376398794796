import React, { useState, useEffect } from 'react';
import { Divider, Grid, Pagination } from '@mui/material';
import queryString from 'query-string';
import { isSameDay } from 'date-fns';
import { useUserIs } from '@clients/shared/src/user/services';
import { useWorkplace } from '@clients/shared/src/place';
import { usePlaceOrState } from '@clients/shared/src/place/services';
import { useApi, stringifyDate, stringifyTime, stringifyDateTime, NONE_FILTER, 
  sortWithFixedLast } from '@clients/shared/src/utils';
import { Page, H6, Frame, BulkLostButton, IconButton } from '../main';
import { Load } from '../_utils';
import { SearchBar, useSearch, useGetModels } from '../searchbar';
import Event from './Event';


const makeSub = (after, before) => isSameDay(after, before)
    ? ('Le '+stringifyDate(after)+' de '+stringifyTime(after) 
      + ' à '+stringifyTime(before))
    : ('Du '+stringifyDateTime(after)+' au '+stringifyDateTime(before));

/** Show events on a specific date and a place. */
export default function Events ({location}) {
  const search = queryString.parse(
    location.search, {parseBooleans: true, parseNumbers: true});
  const { after, before, destination_id, eventType, page=1, is_clean } = search;
  const workplace = useWorkplace();
  const [place, ] = usePlaceOrState(destination_id);
  const userIs = useUserIs();
  const [allEvents, setAllEvents] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const api = useApi();
  const loadEvents = () => api.get().then(setAllEvents)
  useEffect(() => {loadEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [after, before, destination_id, workplace.id, is_clean]);
  const topPageRef = React.useRef(null);
  const setAsLost = event => {
    const data = {bac_shown_id: event.bac, description: 'lost'}
    return api.post('/events', data)
      .then(() => setAllEvents(events => events.map(
        e => e.id===event.id ? {...event, closed: true} : e)));
  };
  const isDelivery = (eventType === 'deliveries');
  const models = useGetModels(search);
  const caretakers = [...new Set(allEvents.map(e => e.bac_caretaker_id))]
    .map(id => ({
      value: id || NONE_FILTER,
      label: id ? allEvents.find(e => e.bac_caretaker_id === id).caretaker
        : 'Sans locataire'}))
    .sort(sortWithFixedLast('label', 'Sans locataire' ))
  const destinations = [...new Set(allEvents.map(e => e.destination_id))]
    .map(id => ({
      value: id || NONE_FILTER,
      label: id ? allEvents.find(e => e.destination_id === id).destination
        : 'Sans destination'}))
    .sort(sortWithFixedLast('label', 'Sans destination' ))
  const filters = [
    {template: 'workplace'},
    {template: 'after', withTime: true},
    {template: 'before', withTime: true},
    {name: 'destination_id',
      label: 'Destination',
      type: 'select',
      items: destinations
    },
    {template: 'models', items: models, key: 'bac_model_id'},
    {template: 'caretaker_id', items: caretakers},
    {template: 'clean', filterFn: null},
    {template: 'notReturned', disabled: showActions},
    {name: 'uniqueBacId', 
      label: 'Identifiant unique',
      type: 'switch',
      filterFn: (isOn, item, i) => 
        !isOn || !allEvents.slice(0, i).some(e => e.bac === item.bac)
    },
  ]
  const [searchBarProps, events] = useSearch(filters, allEvents);
  useEffect(() => { 
    showActions && searchBarProps.setSearchValues(
      values => ({...values, notReturned: true}));
  }, [showActions]);

  return (
    <Page 
      title={isDelivery 
        ? place.name
        : is_clean ? 'LAVÉ' : `RECEPTION${(is_clean===false) ? ' SALE' : ''}`} 
      subtitle={after ? makeSub(new Date(after), new Date(before)) : ''}
      options={userIs('admin') && 
        <IconButton
          type='settings'
          text='Déclarer perte'
          onClick={()=>{setShowActions(e => !e)}}
        />
      }
    >
      <div ref={topPageRef}></div>
      <SearchBar {...searchBarProps}/>
      <Load isLoading={api.isLoading}>
        <Grid container
          justifyContent='center'
          sx={{boxShadow: theme => theme.components.shadows.defaultProps.inner}}
        >
          <Grid item container margin={5} alignItems='center'>
            <Frame secondary={isDelivery}>{events.length}</Frame>
            <H6>{isDelivery ? 'Envois' : 'Réception'}</H6>
          </Grid>
          <Grid item container margin={5} alignItems='center'>
            <Frame error>
              {events.filter(event => !event.closed).length}
            </Frame>
            <H6>{isDelivery ? 'Non rendus' : 'En stock'}</H6>
          </Grid>
        </Grid>
        {showActions && <Grid container justifyContent='center'>
          <BulkLostButton callback={loadEvents} />
          <Divider sx={{width: '100%'}} />
        </Grid>}
        <Grid container >
        {events.slice((page-1)*100, page*100).map( event =>
          <React.Fragment key={event.id}>
            <Event
              event={event} 
              isDelivery={isDelivery} 
              actions={showActions}
              setBacAsLost={() => setAsLost(event)}
            />
            <Divider sx={{width: '100%'}} />
          </React.Fragment>
        )}
        </Grid>
        <Grid item container direction='row' justifyContent='center' p={10}>
          <Pagination
            className="noPrint"
            count={Math.ceil(events.length/100)}
            page={page}
            onChange={(e, newPage) => {
              searchBarProps.setSearchValues(v => ({...v, page: newPage}));
              topPageRef.current.scrollIntoView(); // scroll to top
            }}
          />
        </Grid>
      </Load>
    </Page>
  );
};
