import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Switch } from 'formik-mui';
import { useLogin, useRemoveUser } from '@clients/shared/src/user/services';
import { isEmail, useApi } from '@clients/shared/src/utils';
import { FormPage, Link, Button, SubmitButton, H5, Sub2, Card } from '../main';

/** The login page. */
export default function Login ({ location, history }) {
  const api = useApi();
  const [ capslock, setCapslock ] = useState(false);
  const login = useLogin();
  const removeUser = useRemoveUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {api.get('/users/logout')}, []);
  useEffect(removeUser, []);
  useEffect(() => {
    const checkCapsLock = e => {
      const isCapsLock = e.getModifierState && e.getModifierState('CapsLock');
      setCapslock(isCapsLock ? true : false);
    }
    document.addEventListener('keypress', checkCapsLock);
    return () => document.removeEventListener('keypress', checkCapsLock);
  }, []);
  const { from } = location.state || {from: {pathname: "/"}};

  return (
    <FormPage title='Connexion'>
      {(from.pathname!=='/') && 
      <H5 paragraph>
        Vous devez être connecté pour accéder à la page '{from.pathname}'.<br/>
      </H5>}
      <Card title='Connexion'>
        <Formik 
          initialValues={{email:'', password:'', rememberMe: true}}
          onSubmit={values => (
            login(values.email, values.password, values.rememberMe)
              .then(user => history.push(from), ()=>{}))}
        >
          <Form>
            <Field
              component={TextField}
              name='email'
              label='Email'
              inputProps={{
                autoCorrect: 'off', 
                autoCapitalize: 'none'
              }}
              validate={isEmail}
              autoFocus={false}
              margin='dense'
              variant="standard"
              fullWidth
              required
            />
            <Field
              component={TextField}
              name='password'
              label='Mot de passe'
              type='password'
              margin='dense'
              variant="standard"
              fullWidth
              required
            />
            {capslock && 
            <Sub2 color='error' align='center'>
              Attention, touche MAJ verrouillée.
            </Sub2>}
            <Field
              component={Switch}
              name='rememberMe'
              type='checkbox'
              color='primary'
            />
            <label>Se souvenir de moi</label>
            <SubmitButton> 
              Valider 
            </SubmitButton>
            <Button secondary component={Link} fullWidth
              to='/users/send-recover' 
            >
              Réinitialisation mot de passe
            </Button>
          </Form>
        </Formik>
      </Card>
    </FormPage>);
};

