import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { colors } from '@clients/shared/src/theme';

export default function PositionsDoughnut ({data}) {
  return <Doughnut 
    data={{
      labels: data.map(row => dataDisplay[row.key].label), 
      datasets: [{
        data: data.map(row => row.total),
        backgroundColor: data.map(row => dataDisplay[row.key].color),
      }]
    }} 
    options={{
      responsive: true,
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          backgroundColor: '#FFFFFF7F',
          borderRadius: 5,
        },
      },
    }}
  />;
};

const dataDisplay = {
  'stock': {label:'En stock', color: colors.primary.main,},
  'stockNotClean': {label:'En stock sale', color: colors.primary.dark,},
  'clients': {label:'Chez clients', color: colors.secondary.main,}, 
  'lost': {label:'Perdus il y a moins de 6 mois', color: colors.red,},
  'broken': {label:'Cassés', color: '#e23d49',},
  'bac remisé': { label:'Remisés', color: colors.lightGrey,},
};
