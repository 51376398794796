import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startOfYesterday } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import * as actions from '../redux/actions';
import { getEvents, getEventById, getLoadDate, getEventsSyncRequired, 
  getEventsIsSyncing } from '../redux/selectors';
import { useApi, deviceStorage, useSetErrorMsg, Sentry, makeUrl,
  } from '../utils';
import { formatBacId } from '../scan';
import { eventServices } from './event-services';
import { useGetBacClean, useGetBacEmpty } from '../scan/services';
import { useIsLoggedIn } from '../user/services';

/** Get the list of events from store hook. */
export function useEvents () {
  return useSelector(state => getEvents(state));
};

/** Get an event from store hook. */
export function useEvent (eventId) {
  return useSelector(state => getEventById(state, eventId));
};

/** Get load events date from store hook. */
export function useLoadDate () {
  return useSelector(state => getLoadDate(state));
};

/** Load events in store hook. */
export function useLoadEvents () {
  const dispatch = useDispatch();
  const api = useApi();
  const unsyncEvents = useEvents().filter(({unsync}) => unsync);
  return [
    () => (
      deviceStorage.getItem('events').then(JSON.parse)
        .then(events => events && dispatch(actions.loadEvents(events)))
        .then(() => api.get(makeUrl('/events', {after: startOfYesterday()})))
        .then(events => events.concat(unsyncEvents))
        .then(events => dispatch(actions.loadEvents(events)))),
    api.isLoading,
  ];
};

/** Initialize events in store hook */
export function useInitializeEvents() {
  const dispatch = useDispatch();
  return () =>  dispatch(actions.loadEvents([]));
}

/** Delete an event in store hook. */
export function useDeleteEvent () {
  const dispatch = useDispatch();
  const api = useApi();
  return (eventId, unsync) => {
    if (!eventId) {
      return
    } else if (unsync){
      dispatch(actions.deleteEvent(eventId))
    } else {
      api.delete('/events/' + eventId)
        .then(() => dispatch(actions.deleteEvent(eventId)));
    }
  }
};

/** Replace an unsync event by its sync version in store hook. */
function useReplaceUnsyncEvent () {
  const dispatch = useDispatch();
  return event => dispatch(actions.replaceUnsyncEvent(event));
};

/** Update a field of an event in store hook. */
function useUpdateEvent () {
  const dispatch = useDispatch();
  return (eventId, key, value) => 
    dispatch(actions.updateEvent(eventId, key, value));
};

/** Add an event in store hook. */
export const useAddEvent = () => {
  const dispatch = useDispatch();
  const events = useEvents();
  const updateEvent = useUpdateEvent();
  const setMsg = useSetErrorMsg();
  const bacEmpty = useGetBacEmpty();
  const bacClean = useGetBacClean();
  return (bacId, options) => {
    const { destination_id, bac_updates } = options
    const is_empty = destination_id && bacEmpty;
    const is_clean = destination_id && bacClean;
    const formatError = formatBacId(bacId);
    if (formatError) {
      return setMsg(formatError + `, reçu: ${bacId}`);
    }
    const created = new Date().toISOString();
    if (bac_updates){
      const event = getSameBacUpdatesEvent(bacId, events);
      if (event) {
        const {id, bac_updates: previousUpdates} = event;
        return updateEvent(id,'bac_updates',{...previousUpdates,...bac_updates})
      };
    } else if (same12hEvent(bacId, {is_clean, is_empty, ...options}, events)){
      return setMsg(bacId + ' est déjà scanné.')
    }
    const client_id = uuidv4()
    setMsg();
    console.log('Creating event for bac', bacId, 'at', destination_id);
    const event = {
      is_empty,
      is_clean,
      ...options, 
      created,
      bac_shown_id: bacId,
      bac: bacId,
      id: `temp_${client_id}`,
      client_id,
      unsync: true,
    };
    const lastEvent = events.find(e => e.bac===bacId && !(e.closed));
    lastEvent && updateEvent(lastEvent.id, 'closed', true);
    dispatch(actions.addEvent(event));
  };
};

const getSameBacUpdatesEvent = (bacId, events) => 
  events.filter(e =>
    e.bac===bacId
    && e.bac_updates
    && e.unsync
  ).at(0)

const same12hEvent = (bacId, {destination_id, ...otherOptions}, events) => {
  const bacEvents = events.filter(e => e.bac===bacId)
  const similarEvents = eventServices.filter(bacEvents, {
    notReturned: true,
    placeId: destination_id,
    ...otherOptions
  })
  return eventServices.last12hEvents(similarEvents).at(0)
}

/** Load events from API and push unsync events to API in store hook. */
export function useRefreshEvents () {
  const [loadEvents, isLoading] = useLoadEvents();
  const setRequireEventsSync = useSetRequireEventsSync();
  const isSyncing = useEventsIsSyncing();
  return [
    () => loadEvents().then(() => setRequireEventsSync(true)),
    isLoading || isSyncing
  ];
}

/** Sync scan events in store hook. */
export function useSyncScans () {
  const api = useApi();
  const replaceUnsyncEvent = useReplaceUnsyncEvent();
  const dispatch = useDispatch();
  const setErrorMsg = useSetErrorMsg();
  return events => {
    const timedEvents = events.map(event => ({
      ...event,
      device_current_time: 
        Date.now() - Date.parse(event.created) > 10*1000
        ? new Date().toISOString()
        : undefined
    }))
    return api.post('/events', timedEvents)
      .then(events => events.forEach(replaceUnsyncEvent))
      .catch(err => (err.cause?.status === 0)
        ? true // set keepIdling in DataSync
        : Promise.reject())
      .catch(() => {
        setErrorMsg('');
        return Promise.allSettled(timedEvents.map((event, i) => 
          new Promise(resolve => setTimeout(resolve, i*100))
            .then(() => api.post('/events', event))
            .then(replaceUnsyncEvent)
            .catch(err => {
              console.log(err)
              Sentry.captureException(err)
              dispatch(actions.deleteEvent(event.id))
            })
          ))
      })
  };
};

/** Sync bac updates in store hook. */
export function useSyncUpdates () {
  const api = useApi();
  const updateEvent = useUpdateEvent();
  const dispatch = useDispatch();
  return events => {
    return Promise.allSettled(events.map((event, i) =>
      new Promise(resolve => setTimeout(resolve, i*100))
        .then(() => api.put(`/bacs/${event.bac_shown_id}`, event.bac_updates))
        .then(() => updateEvent(event.id, 'unsync', false))
        .catch(err => (err.cause?.status === 0)
          ? {keepIdling: true}
          : dispatch(actions.deleteEvent(event.id))
        )
      ))
    .then(results => results.some(r => r.value?.keepIdling))
  };
};

/** Initialize, load and refresh events hook. */
export function useAutoupdateEvents () {
  const isLoggedIn = useIsLoggedIn();
  const [loadEvents, isLoading] = useLoadEvents();
  const initializeEvents = useInitializeEvents();
  const [isFresh, setIsFresh] = useState(false);
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      setIsFresh(false);
    }, 3*60*60*1000); // 3 hours
    return () => clearInterval(refreshInterval);
  }, []);
  useEffect(() => {
    if(!isLoggedIn){
      initializeEvents();
      setIsFresh(false);
    } else if(!isFresh){
      loadEvents();
      setIsFresh(true);
    }
  }, [isLoggedIn, isFresh])
}

/** Set if sync of events is required from store hook. */
export function useSetRequireEventsSync() {
  const dispatch = useDispatch();
  return isRequired => dispatch(actions.setEventsSyncRequired(isRequired));
}

/** Set if sync of events is pending from store hook. */
export function useSetEventsIsSyncing() {
  const dispatch = useDispatch();
  return isSyncing => dispatch(actions.setEventsIsSyncing(isSyncing));
}

/** Get if sync of events is required from store hook. */
export function useEventsSyncRequired() {
  return useSelector(state => getEventsSyncRequired(state))
}

/** Get if sync of events is pending from store hook. */
export function useEventsIsSyncing() {
  return useSelector(state => getEventsIsSyncing(state));
}

/** Get similar and recent events. */  
export function useSimilarRecentEvents({place, ...otherFilters}) {
  const events = useEvents();
  const is_empty = useGetBacEmpty();
  const is_clean = useGetBacClean();
  const similarEvents = eventServices.filter(events, {
    notReturned: true,
    placeId: place?.id,
    is_empty,
    is_clean,
    ...otherFilters
  })
  return eventServices.last12hEvents(similarEvents)
}
