import React from 'react';
import { Box } from '@mui/material';
import { CloudOff } from '@mui/icons-material';
import { useIsOnline } from '@clients/shared/src/utils';
import { H6 } from './';


export default function OfflineIndicator() {
  const isOffline = !useIsOnline();
  return(
    <Box
      sx={{
        display: isOffline ? 'flex' : 'none',
        backgroundColor: 'grey.light',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.75rem'
      }}
    >
      <CloudOff sx={{marginRight: 2}}/>
      <H6>Données non synchronisées</H6>
    </Box>
  )
}
