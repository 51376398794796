import React, { useState, Fragment } from 'react';
import { Grid, Accordion, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import EventsGroupsList from './EventsGroupsList';
import { stringifyDate, stringifyTime } from '@clients/shared/src/utils';
import { H6, Tooltip, Frame } from '../main';


/** Displays a period of events grouped by destinations. */
export default function EventsPeriod (props) {
  const [expanded, setExpanded] = useState(props.expanded || false)
  const { placeId, events } = props;
  const startDate = events[events.length - 1].date;
  const endDate = events[0].date;
  const returns = events.filter(e => e.destination_id===placeId);
  const deliveries = events.filter(e => e.destination_id!==placeId);
  const isSameDay = startDate.getDate() === endDate.getDate();
  return (
    <Accordion
      square 
      expanded={expanded}
      onClick={() => setExpanded(expanded => !expanded)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        sx={{
          backgroundColor: 'rgba(16, 123, 3, 0.05)',
          boxShadow: theme => theme.components.shadows.defaultProps.outer
        }}
      >
        <Grid container sx={{spacing: 2}}>
          <Grid item container sx={{marginRight: 2}}>
            {deliveries.length > 0 &&
            <Tooltip text={<ModelCnter events={deliveries} title="Envoi"/>}>
              <Frame secondary>{deliveries.length}</Frame>
            </Tooltip>}
            {returns.length > 0 &&
            <Tooltip text={<ModelCnter events={returns} title="Réception"/>}>
              <Frame>{returns.length}</Frame>
            </Tooltip>}
          </Grid>
          <H6
            sx={{
              fontWeight: 100,
              color: '#616161',
              '& b': {
                color: 'text.primary'
              },
            }}
          >
            {isSameDay ? 'Le ' : 'Du '}
            <b>{stringifyDate(startDate, {weekday: 'short'})}</b>
            {(isSameDay ? ' de ' : ' à ') + stringifyTime(startDate)}
            {!isSameDay && ' au ' + stringifyDate(endDate)}
            {' à ' + stringifyTime(endDate)}
          </H6>
        </Grid>
      </AccordionSummary>
      <EventsGroupsList events={events} placeId={placeId} />
    </Accordion>);
}

const cntModels = events => (
  events.map(e => e.bac_model || 'sans modèle').reduce(
    (cnter, model) => ({...cnter, [model]: (cnter[model] || 0) + 1}), {}));

const ModelCnter = ({events, title}) => (
  (<>
    {title} :<br/>
    {Object.entries(cntModels(events))
      .sort(([modelA, cntA], [modelB, cntB]) => modelA > modelB ? 1 : -1)
      .map(([model, cnt], i) => (
        <Fragment key={i}>{cnt} {model}<br/></Fragment>))}
  </>))
