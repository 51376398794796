import React from 'react';
import { Avatar as MuiAvatar, Box } from '@mui/material';

/** User avatar. */
export default function Avatar({user={}}) {
  const { first, last } = user;
  return (
    <Box 
      component={MuiAvatar}
      width={{xs: 28, md:40}}
      height={{xs: 28, md:40}}
      fontSize={{xs: 15, md:20}}
      sx={{border: '1px solid grey'}}
    >
      {(first ? first[0] : '') + (last ? last[0] : '')}
    </Box>); 
};
