import React from 'react';
import { Box } from '@mui/material';
import { useIsLoggedIn } from '@clients/shared/src/user/services';
import Link from './Link';
import Button from './Button';
import QuickAccess from './QuickAccess';
import { H1, H5, H6, Highlight } from './Typography';

/** The homepage, presenting the app. */
export default function Home () {
  const isLoggedIn = useIsLoggedIn();
  document.title = "Home - Pandobac";
  return (
    <Box m={[5, 8]}>
      <H1>Pandobac Scanner</H1>
      <H5 paragraph>
        Bienvenue sur le <Highlight>tracker de Pandobac.</Highlight>
      </H5>
      <H6 paragraph>
        Cet outil vous permet de suivre facilement les objets marqués par 
        un code barre ou un flashcode Pandobac.
      </H6>
      <br />
      {!isLoggedIn && 
        <>
          <Button> 
            <Link to='/users/login'>Se connecter</Link>
          </Button>
        </>
      }
      {isLoggedIn && 
        <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
          <QuickAccess />
        </Box>
      }
    </Box>
  );
};
